import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { ConfigService } from 'ngx-envconfig';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { ConfirmationService } from 'src/app/confirm/confirmation.service';
import { ApploaderService } from 'src/app/services/apploader/apploader.service';
import { DeliveryService } from 'src/app/services/delivery.service';
import { GenericserviceService } from 'src/app/services/genericservice.service';
import { GlobalconfigComponent } from '../gestionlivreur/globalconfig/globalconfig.component';
import { NewlivreurComponent } from '../gestionlivreur/newlivreur/newlivreur.component';
import { ShowlivreurComponent } from '../gestionlivreur/showlivreur/showlivreur.component';
import { ZonedeliveryComponent } from '../gestionlivreur/zonedelivery/zonedelivery.component';
import { ShowDeliveryAccountComponent } from './show-delivery-account/show-delivery-account.component';



interface jsPDFWithPlugin extends jsPDF {
  autoTable: (options: UserOptions) => jsPDF;
}

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
import { UserOptions } from 'jspdf-autotable';


@Component({
  selector: 'fury-gestionstart',
  templateUrl: './gestionstart.component.html',
  styleUrls: ['./gestionstart.component.scss']
})
export class GestionstartComponent implements OnInit {


  public items: any[] = [];
  temp = [];
  rows = [];
  columns = [];
  scrollBarHorizontal = (window.innerWidth < 1200);
  public getItemSub: Subscription;

  // Habilitation
  public creationLivreur: boolean;

  // expandable
  @ViewChild('myTable') table: any;
  expanded: any = {};
  timeout: any;

  check: any[] = [
    {
      "libele": "TOUT",
      "values": 2
    },
    {
      "libele": "EN ATTENTE",
      "values": 0
    },
    {
      "libele": "VALIDE",
      "values": 1
    },
    {
      "libele": "INVALIDE",
      "values": -1
    }

  ]

  public itemForm: FormGroup;



  constructor(private logger: NGXLogger,
    private route: Router,
    private dialog: MatDialog,
    public formBuilder: FormBuilder,
    private snackbar: MatSnackBar,
    private confirmService: ConfirmationService,
    private utilisateurService: GenericserviceService,
    private deliveryService: DeliveryService,
    private loader: ApploaderService,

    private datePipe: DatePipe,

    private fb: FormBuilder,
    private snack: MatSnackBar,

    private configService: ConfigService) { }


  ngOnInit() {


    let dateNow: any = new Date();
    dateNow = this.datePipe.transform(dateNow, "yyyy-MM-dd");

    this.itemForm = this.fb.group({
      debut: [dateNow + 'T00:01:01'],
      fin: [dateNow + 'T23:59:59'],
      type: [2]
    });



    this.getAll();
  }


  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    var columns = Object.keys(this.temp[0]);
    // Removes last "$$index" from "column"
    columns.splice(columns.length - 1);

    // console.log(columns);
    if (!columns.length)
      return;

    const rows = this.temp.filter(function (d) {
      for (let i = 0; i <= columns.length; i++) {
        let column = columns[i];
        // console.log(d[column]);
        if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
          return true;
        }
      }
    });

    this.items = rows;
  }



  opendetail(value: any) {



    this.dialog.open(ShowDeliveryAccountComponent,
      {
        width: '720px',
        data: { title: '', payload: value }
      }
    ).afterClosed().subscribe((res) => {
      console.log(res);
      if (!res) {
        return;
      }
    });
  }


  getAll() {
    this.getItemSub = this.deliveryService.get("fileattente/delivery/findalltransactionstart")
      .subscribe(data => {

        switch (data.status) {

          default: {

            this.logger.trace('data', data.body);
            let response: any[] = data.body;
            this.logger.trace('response', response);

            let resp1: any[] = response;

            this.items = this.temp = resp1;
            // this.dataSource = new MatTableDataSource(this.items);

            break;
          }


        }
      },
        error => {
          console.log('error');
          console.log(error);

          switch (error.status) {

            default: {

              break;
            }
          }
        });
  }


  searchByDate() {
    let date = new Date(this.itemForm.get("debut").value);
    let date1 = new Date(this.itemForm.get("fin").value);

    let request: any = this.itemForm.value;

    let from;
    let to;

    if ((date.toString() != 'Invalid Date')) {
      from = this.itemForm.get("debut").value;
    }

    if (date1.toString() != null) {
      to = this.itemForm.get("fin").value;
    }

    let newDate = new Date(from);
    let newDate1 = new Date(to);

    if ((newDate.toDateString() !== "Invalid Date") && (newDate1.toDateString() !== "Invalid Date")) {
      if (newDate1 >= newDate) {
        request.debut = this.datePipe.transform(newDate, "yyyy-MM-dd HH:mm:ss");
        request.fin = this.datePipe.transform(newDate1, "yyyy-MM-dd HH:mm:ss");
      } else {
        this.snackbar.open('Format de date invalide ', 'OK', { duration: 4000 });
        return;
      }
    } else {
      this.snackbar.open('Format de date invalide ', 'OK', { duration: 4000 });
      return;
    }

 

    console.log(request);

    this.deliveryService.post(request, "fileattente/delivery/findalltransactionstartbydate").subscribe(data => {

      this.logger.trace(data);


      switch (data.status) {
        case 200: {

          let response: any[] = data.body;
          this.logger.trace('response', this.itemForm.get('type').value);

          let resp1: any[] = [];
          if (this.itemForm.get('type').value === 2) {
            resp1 = response;
          }

          if (this.itemForm.get('type').value === -1) {

            if (response != null && response.length > 0) {
              for (const i of response) {
                if (i.statuscode === -1) {
                  resp1.push(i);
                }
              }
            }

          }

          if (this.itemForm.get('type').value === 1) {
            if (response != null && response.length > 0) {
              for (const i of response) {
                if (i.statuscode === 1) {
                  resp1.push(i);
                }
              }
            }
          }

          if (this.itemForm.get('type').value === 0) {
            if (response != null && response.length > 0) {
              for (const i of response) {
                if (i.statuscode === 0) {
                  resp1.push(i);
                }
              }
            }
          }


          this.items = this.temp = resp1;
          // this.dataSource = new MatTableDataSource(this.items);


          break;
        }

      }
    },
      error => {
        this.logger.trace("erreur retour");
        // this.loading.dismiss('messagesService.loadMessagesOverview');
        switch (error.status) {



          default: {

            // this.FormReg(" erreur de connexion !!! Veuillez ressayer");
            this.logger.error("Check API", error);

            this.snackbar.open('Echec', 'OK', {
              duration: 10000
            });
            break;
          }
        }
      }
    );

  }


  goToRounting(value) {
    this.route.navigateByUrl(value);
  }



  exportPdf() {
    let itemscsv: any[] = [];
    let itemslocal: any[] = this.items;
    if (itemslocal.length > 0) {
      itemslocal.forEach(function (element) {
        

        var my_json = {
          "Operation": element.operation,
          "Motif": element.motif,
          "Montant": element.montant,
          "Livreur": element.livreurname,
          "STATUS": element.status
        };


        itemscsv.push(my_json);
      });
    }

    console.log(itemscsv);
    var columns = [
      { title: "Operation", dataKey: "Operation" },
      { title: "Motif", dataKey: "Motif" },
      { title: "Montant", dataKey: "Montant" },
      { title: "Livreur", dataKey: "Livreur" },
      { title: "STATUS", dataKey: "STATUS" }
    ];
    var rows = itemscsv;

    const doc = new jsPDF('portrait', 'px', 'a4') as jsPDFWithPlugin;
    doc.autoTable({
      body: rows,
      columns: columns,
      didParseCell: function (data) {
        if (data.column.raw.displayProperty) {
          var prop = data.column.raw.displayProperty;
          var text = data.cell.raw[prop];
          if (text && text.length > 0) {
            data.cell.text = text;
          }
        }
      }
    });
    doc.save('compte-start.pdf');
  }

  exportXls() {
    let itemscsv: any[] = [];
    let itemslocal: any[] = this.items;
    if (itemslocal.length > 0) {
      itemslocal.forEach(function (element) {
        console.log(element);
       
        var my_json = {
          "Operation": element.operation,
          "Motif": element.motif,
          "Montant": element.montant,
          "Livreur": element.livreurname,
          "STATUS": element.status
        };

        itemscsv.push(my_json);
      });
      console.log(itemscsv);

      this.exportAsExcelFile(itemscsv, 'compte-start');
    }
  }


  

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

}
