import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { ConfirmationService } from 'src/app/confirm/confirmation.service';
import { Clienttype } from 'src/app/models/Agent';
import { AbbatementService } from '../../abbatement/abbatement.service';
import { GestioncorporateService } from '../../gestioncorporate/gestioncorporate.service';

@Component({
  selector: 'fury-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.scss']
})
export class UpdateComponent implements OnInit {

 
  getItemSub: Subscription;
  public itemForm: FormGroup;
  client: Clienttype[];

  selectedTypecard : any ;


  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UpdateComponent>,
    private fb: FormBuilder,
    private snack: MatSnackBar,
    private confirmService: ConfirmationService,
    private abbatementService: AbbatementService,
    private pointservice: GestioncorporateService,
    private logger: NGXLogger) { }

  ngOnInit() {
    this.getAllCorporate();
    this.buildItemForm(this.data.payload);
  }

  buildItemForm(item){
    let categorie : any = item ;
    this.logger.trace(categorie);
    this.selectedTypecard  = item.clienttype.clienttypeid ;
    this.itemForm = this.fb.group({
      categoriecorpoid: [ categorie.categoriecorpoid || '', [Validators.required]],
      categoriecorpolibelle: [ categorie.categoriecorpolibelle || '', [Validators.required]],
      categoriecorpodescription: [categorie.categoriecorpodescription ||  ''],
      clienttype: [ this.selectedTypecard ||'', [Validators.required]],
    });
  }


  onSelectTypecard(item) {
    this.itemForm.controls['clienttype'].setValue(item.clienttypeid);
  }


  getAllCorporate() {
    this.getItemSub = this.pointservice.get('clienttype')
      .subscribe(data => {
        const statuscode = data.status;
        this.logger.trace('DATA', data);
        switch (statuscode) {
          case 200: {
            console.log('**************');
            this.client = data.body;
            console.log(this.client);
            // this.temp = data.body;
            break;
          }

          case 204: {
            // this.snack.open('Aucune TVA disponible', 'OK', { duration: 4000 })
            break;
          }

          default: {
            // this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

            break;
          }

        }
      },
        error => {
          this.logger.error('error');
          this.logger.error(error);

          switch (error.status) {

            default: {

              //    this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

              break;
            }
          }
        });
  }


  submit(){
    this.logger.fatal(this.selectedTypecard);
    let response : any = this.itemForm.value ;
    this.dialogRef.close(response);
  }


}
