import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { MenuService } from 'src/app/pages/gestionmenu/menu.service';

@Component({
  selector: 'fury-update-menu-reduction',
  templateUrl: './update-menu-reduction.component.html',
  styleUrls: ['./update-menu-reduction.component.scss']
})
export class UpdateMenuReductionComponent implements OnInit {

  menus: any[] = [];
  profils: any[] = [];

  articles: any[] = [];


  foods: any[] = [];

  public getItemSub: Subscription;
  form: FormGroup;

  profil: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UpdateMenuReductionComponent>,
    private fb: FormBuilder, private menuservice: MenuService,
    private snackbar: MatSnackBar,
    private logger: NGXLogger
  ) { }

  ngOnInit() {
    this.form = this.fb.group({

      abtmntid: ['', Validators.required],
      abtmntlib: ['', Validators.required],
      typecode: ['1', Validators.required],
      type: ['menu', Validators.required],
      profilcode: ['', Validators.required],
      menuabbatementid: ['', Validators.required],
      linkmenuarticle: this.fb.array([])
    });



    let abtmnt = this.data.payload;

    if (abtmnt) {
      this.checkItems(abtmnt);
    }

  
    this.getallProfil();

    this.getallMenu();
  }


  get linkmenuarticle(): FormArray {
    return this.form.get("linkmenuarticle") as FormArray
  }

  addLinkmenuarticle(articleid, article, catid) {
    this.linkmenuarticle.push(this.newLinkmenuarticle(articleid, article, catid));
  }

  newLinkmenuarticle(articleid, article, catid): FormGroup {
    return this.fb.group({
      'articleid': [articleid, Validators.required],
      'montant': [0, Validators.required],
      'article': [article, Validators.required],
      'categorieid': [catid, Validators.required],
    })
  }



  updateLinkmenuarticle(articleid, article, catid ,montant ): FormGroup {
    return this.fb.group({
      'articleid': [articleid, Validators.required],
      'montant': [montant, Validators.required],
      'article': [article, Validators.required],
      'categorieid': [catid, Validators.required],
    })
  }

  getallProfil() {
    this.getItemSub = this.menuservice.getAllFoodByCatid("typecarte/enable")
      .subscribe(data => {

        let responseany: any = data;

        this.logger.info("------------------------");
        this.logger.trace(responseany);
        this.logger.info("------------------------");

        switch (data.status) {

          case 200: {
            this.profils = data.body;
            break;
          }





        }
      },
        error => {
          console.log("error");
          console.log(error);

          switch (error.status) {

            default: {

              break;
            }
          }
        });
  }

  getallMenu() {
    this.getItemSub = this.menuservice.getAllFoodByCatid("parcours/corporate/getallmenu")
      .subscribe(data => {

        let responseany: any = data;

        this.logger.trace(responseany);

        switch (data.status) {

          case 200: {
            this.menus = data.body;
            break;
          }





        }
      },
        error => {
          console.log("error");
          console.log(error);

          switch (error.status) {

            default: {

              break;
            }
          }
        });
  }



  checkItems(abtmnt) {
    this.getItemSub = this.menuservice.getAllFoodByCatid("parameter/abtmnt/getmenuarticle/" + abtmnt.abtmntid )
      .subscribe(data => {

        let responseany: any = data;

        this.logger.trace(responseany);

        switch (data.status) {

          case 200: {


            let allItems: any[] = data.body;

            console.log(allItems);

            if (allItems && allItems.length > 0) {

              let checkKey: string = allItems[0].linkmenuid;

        
              this.form = this.fb.group({
                abtmntid: [abtmnt.abtmntid || '', Validators.required],
                abtmntlib: [abtmnt.abtmntlib || '', Validators.required],
                typecode: ['1', Validators.required],
                type: ['menu', Validators.required],
                profilcode: [abtmnt.profilcode || '', Validators.required],
                menuabbatementid: [checkKey, Validators.required],
                linkmenuarticle: this.fb.array([])
              });



              for (const a of allItems) {
                this.linkmenuarticle.push(this.updateLinkmenuarticle(a.articleid, a.article, a.categorieid , a.montant));

              }

              this.form.get('menuabbatementid').valueChanges.subscribe(data => {
                this.getallFood(data);
              });

            }

            break;
          }





        }
      },
        error => {
          console.log("error");
          console.log(error);

          switch (error.status) {

            default: {

              break;
            }
          }
        });
  }





  getallFood(id) {
    this.getItemSub = this.menuservice.getAllFoodByCatid("parameter/abtmnt/getfoodbymenu/" + id)
      .subscribe(data => {

        let responseany: any = data;

        this.logger.trace(responseany);

        switch (data.status) {

          case 200: {

            this.foods = data.body;

            if (this.foods != null && this.foods.length > 0) {
              let plats: any[] = this.foods;
              let platfinal: any[] = [];
              this.articles = [];

              /*
              plats.forEach(element => {
                let p: any = {};
                p.articleid = element.foodid;
                p.article = element.foodname;
                p.categorieid = element.categorie.categorieid;
                p.categorie = element.categorie.categoriename;
                p.montant = 0;
                platfinal.push(p);
                
              });
              */

              this.form.setControl('linkmenuarticle', this.fb.array([]))


              for (const p of this.foods) {
                this.addLinkmenuarticle(p.foodid, p.foodname, p.categorie.categorieid);
              }

              console.log(this.form.value);


              this.articles = platfinal;
            }

            break;

          }





        }
      },
        error => {
          console.log("error");
          console.log(error);

          switch (error.status) {

            default: {

              break;
            }
          }
        });
  }

  saveReduction() {

    const benFormArray = this.form.get('linkmenuarticle') as FormArray;
    let items: any[] = [];
    let dataCategorie: any[] = [];

    for (let index = 0; index < benFormArray.length; index++) {
      const element = benFormArray.controls[index] as FormGroup;
      let it: any = {};
      if (element.invalid) {
        let montantFormControl = element.controls.montant;
        if (montantFormControl.invalid) {
          this.snackbar.open('Veuillez renseigner correctement tous les montants ', 'OK', {
            duration: 10000
          });
          return;
        }
      }
      console.log(element.value);
      let p: any = {};
      p.articleid = element.value.articleid;
      p.article = element.value.article;
      p.categorieid = element.value.categorieid;
      p.montant = element.value.montant;
      items.push(p);
      dataCategorie.push(element.value.categorieid);
    }
    const propertyValues = Object.values(this.count(dataCategorie));

    if (propertyValues.length > 2) {
      this.snackbar.open('Veuillez selectioner un menu qui a 2 categories au maxximum ', 'OK', {
        duration: 10000
      });
      return;
    }

    let request: any = this.form.value;
    request.linkmenuarticle = items;
    console.log(request);
    this.dialogRef.close(request);

  }


  count(arr) {
    return arr.reduce(function (a, b) {
      a[b] = a[b] + 1 || 1
      return a;
    }, {});
  }


}
