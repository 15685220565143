import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatTableDataSource, MatPaginator, MatDialog, MatSnackBar } from '@angular/material';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ConfigService } from 'ngx-envconfig';
import { NGXLogger } from 'ngx-logger';
import { NgxPermissionsService } from 'ngx-permissions';
import { Subscription } from 'rxjs';
import { ConfirmationService } from 'src/app/confirm/confirmation.service';
import { Agentstorage } from 'src/app/models/agentstorage';
import { Pointlivraison } from 'src/app/models/pointvente';
import { ApploaderService } from 'src/app/services/apploader/apploader.service';
import { GestionpointlivraisonService } from '../gestionpointlivraison.service';
import { ZoneUpdateComponent } from './zone-update/zone-update.component';
import { ZoneComponent } from './zone/zone.component';

@Component({
  selector: 'fury-pointlivraisonzone',
  templateUrl: './pointlivraisonzone.component.html',
  styleUrls: ['./pointlivraisonzone.component.scss']
})
export class PointlivraisonzoneComponent implements OnInit {

  displayedColumns: string[] = ['pointlivraisonquartiernom', 'pointlivraisonquartierprix', 'actions'];
  dataSource = new MatTableDataSource<any>([]);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public getItemSub: Subscription;
  pointvente: any[] = [];

  key: string;

  constructor(private dialog: MatDialog, public formBuilder: FormBuilder,
    private snackbar: MatSnackBar, private datePipe: DatePipe, private router: Router,
    private configService: ConfigService, private _activatedRoute: ActivatedRoute,
    private confirmService: ConfirmationService, private loader: ApploaderService,
    private permissionsService: NgxPermissionsService, private logger: NGXLogger,
    private pointservice: GestionpointlivraisonService) { }

  ngOnInit() {

    this._activatedRoute.queryParams.forEach((params: Params) => {
      if (params['key']) {
        this.key = params['key'];
        this.loadPointlivraison();
      } else {
        //this.router.navigate(['/']);
      }
    });
  }


  loadPointlivraison() {
    this.getItemSub = this.pointservice.get("pointlivraisonquartier/getbyzoneid/" + this.key)
      .subscribe(data => {

        let statuscode = data.status;
        this.logger.trace('DATA', data);

        switch (statuscode) {
          case 200: {
            this.pointvente = data.body;

            this.dataSource.data = this.pointvente;;
            this.dataSource.paginator = this.paginator;
            //this.temp = data.body;
            break;
          }

          case 204: {
            //this.snack.open('Aucune TVA disponible', 'OK', { duration: 4000 })
            break;
          }

          default: {
            // this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

            break;
          }

        }
      },
        error => {
          this.logger.error("error");
          this.logger.error(error);

          switch (error.status) {

            default: {

              //    this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

              break;
            }
          }
        });
  }



  createZonelivraison() {
    this.dialog.open(ZoneComponent, { width: '720px' }).afterClosed().subscribe((res) => {
      console.log(res);

      if (!res) {
        return;
      }

      let request: any = res;
      request.pointlivraisonid = this.key;
      let u = new Agentstorage();
      u = JSON.parse(localStorage.getItem("curentuser"));
      if (u != null) {
        request.userpisteaudit = u.agent.agentemail + " " + u.agent.agentfirstname + " " + u.agent.agentlastname;
      }
      this.pointservice.post(request, "pointlivraisonquartier").subscribe(data => {
        this.logger.trace('DATA', data);
        switch (data.status) {

          case 201: {

            console.log("data");
            console.log(data);

            this.loadPointlivraison();
            this.loader.close();
            this.snackbar.open('zone de livraison crée avec succes ', 'OK', {
              duration: 10000
            });

            break;
          }

        }
      },
        error => {
          this.logger.error('ERREUR', error);
          switch (error.status) {

            case 409: {
              //this.getallFood();
              this.loader.close();
              this.snackbar.open('Cette zone de livraison existe deja ', 'OK', {
                duration: 10000
              });
              break;
            }

            default: {
              this.loader.close();

              this.snackbar.open('Echec !! Une erreur est survenue ', 'OK', {
                duration: 10000
              });
              break;
            }
          }
        });
    });
  }


  updateZonelivraison(value) {
    this.dialog.open(ZoneUpdateComponent, { width: '720px', data: { title: '', payload: value } }).afterClosed().subscribe((res) => {
      console.log(res);

      if (!res) {
        return;
      }

      let request: any = value;
      request.pointlivraisonid = this.key;
      request.pointlivraisonquartierprix = res.pointlivraisonquartierprix;
      let u = new Agentstorage();
      u = JSON.parse(localStorage.getItem("curentuser"));
      if (u != null) {
        request.userpisteaudit = u.agent.agentemail + " " + u.agent.agentfirstname + " " + u.agent.agentlastname;
      }
      this.pointservice.put(request, "pointlivraisonquartier").subscribe(data => {
        this.logger.trace('DATA', data);
        switch (data.status) {

          case 200: {

            console.log("data");
            console.log(data);

            this.loadPointlivraison();
            this.loader.close();
            this.snackbar.open('zone de livraison mis à jour avec succes ', 'OK', {
              duration: 10000
            });

            break;
          }

        }
      },
        error => {
          this.logger.error('ERREUR', error);
          switch (error.status) {

            case 409: {
              //this.getallFood();
              this.loader.close();
              this.snackbar.open('Cette zone de livraison existe deja ', 'OK', {
                duration: 10000
              });
              break;
            }

            default: {
              this.loader.close();

              this.snackbar.open('Echec !! Une erreur est survenue ', 'OK', {
                duration: 10000
              });
              break;
            }
          }
        });
    });
  }


  deleteItem(row) {
    this.confirmService.confirm({
      message: `Voulez vous supprimer la zone de livraison : ${row.pointlivraisonquartiernom}?  `
    })
      .subscribe(res => {
        if (res) {

          console.log("resultat obtenu ");
          console.log(row);

          let u = new Agentstorage();
          u = JSON.parse(localStorage.getItem("curentuser"));
          if (u != null) {
            row.userpisteaudit = u.agent.agentemail + " " + u.agent.agentfirstname + " " + u.agent.agentlastname;
          }

          this.pointservice.post(row, "pointlivraisonquartier/delete").subscribe(data => {

            this.loadPointlivraison();
            this.snackbar.open('Success ', 'OK', {
              duration: 10000
            });

          },
            error => {

              this.snackbar.open('Echec !! Une erreur est survenue ', 'OK', {
                duration: 10000
              });
              console.log("erreur retour");
              console.log(error);
            }
          );
        }
      })
  }

}
