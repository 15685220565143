import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { MenuService } from 'src/app/pages/gestionmenu/menu.service';
import { PanierReductionComponent } from '../panier-reduction/panier-reduction.component';

@Component({
  selector: 'fury-update-panier-reduction',
  templateUrl: './update-panier-reduction.component.html',
  styleUrls: ['./update-panier-reduction.component.scss']
})
export class UpdatePanierReductionComponent implements OnInit {

  menus: any[] = [];
  profils: any[] = [];

  articles: any[] = [];


  foods: any[] = [];

  public getItemSub: Subscription;
  form: FormGroup;

  profil: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UpdatePanierReductionComponent>,
    private fb: FormBuilder, private menuservice: MenuService,
    private snackbar: MatSnackBar,
    private logger: NGXLogger
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      abtmntid: ['', Validators.required],
      abtmntlib: ['', Validators.required],
      typecode: ['3', Validators.required],
      type: ['panier', Validators.required],
      profilcode: ['', Validators.required],
      montantype: ['', Validators.required],
      montant: [0, Validators.required],
    });

    let abtmnt = this.data.payload;

    if (abtmnt) {
      this.form = this.fb.group({
        abtmntid: [abtmnt.abtmntid, Validators.required],
        abtmntlib: [abtmnt.abtmntlib, Validators.required],
        typecode: ['3', Validators.required],
        type: ['panier', Validators.required],
        profilcode: [abtmnt.profilcode, Validators.required],
        montantype: [abtmnt.montantype, Validators.required],
        montant: [abtmnt.montant, Validators.required],
      });
    }

    this.getallProfil();

  }




  getallProfil() {
    this.getItemSub = this.menuservice.getAllFoodByCatid("typecarte/enable")
      .subscribe(data => {

        let responseany: any = data;

        this.logger.info("------------------------");
        this.logger.trace(responseany);
        this.logger.info("------------------------");

        switch (data.status) {

          case 200: {
            this.profils = data.body;
            break;
          }





        }
      },
        error => {
          console.log("error");
          console.log(error);

          switch (error.status) {

            default: {

              break;
            }
          }
        });
  }



  saveReduction() {



    let request: any = this.form.value;
    if (request.montantype === 'taux') {
      request.montantypecode = '1';
    } else {
      request.montantypecode = '2';
    }
    console.log(request);
    this.dialogRef.close(request);

  }


  count(arr) {
    return arr.reduce(function (a, b) {
      a[b] = a[b] + 1 || 1
      return a;
    }, {});
  }

}
