import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SimplificationcorporateComponent } from './simplificationcorporate.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule, MatChipsModule, MatDatepickerModule, MatFormFieldModule, MatListModule, MatPaginatorModule, MatSelectModule, MatTableModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { BreadcrumbsModule } from 'src/@fury/shared/breadcrumbs/breadcrumbs.module';
import { FuryCardModule } from 'src/@fury/shared/card/card.module';
import { MaterialModule } from 'src/@fury/shared/material-components.module';
import { Step1CorpComponent } from './step1-corp/step1-corp.component';
import { ListModule } from 'src/@fury/shared/list/list.module';
import { QuickInfoWidgetModule } from '../dashboard/widgets/quick-info-widget/quick-info-widget.module';
import { AddCorpComponent } from './step1-corp/add-corp/add-corp.component';
import { Step2FormabnComponent } from './step2-formabn/step2-formabn.component';
import { Step2MenuComponent } from './step2-menu/step2-menu.component';
import { AddMenuComponent } from './step2-menu/add-menu/add-menu.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { Step3ReductionComponent } from './step3-reduction/step3-reduction.component';
import { MenuReductionComponent } from './step3-reduction/menu-reduction/menu-reduction.component';
import { ArticleReductionComponent } from './step3-reduction/article-reduction/article-reduction.component';
import { PanierReductionComponent } from './step3-reduction/panier-reduction/panier-reduction.component';
import { PvReductionComponent } from './step3-reduction/pv-reduction/pv-reduction.component';
import { Step5FormuleComponent } from './step5-formule/step5-formule.component';
import { AddStepFormuleComponent } from './step5-formule/add-step-formule/add-step-formule.component';
import { Step6ConditionComponent } from './step6-condition/step6-condition.component';
import { AddConditionComponent } from './step6-condition/add-condition/add-condition.component';
import { UpdateConditionComponent } from './step6-condition/update-condition/update-condition.component';
import { UpdateStepFormuleComponent } from './step5-formule/update-step-formule/update-step-formule.component';
import { FurySharedModule } from 'src/@fury/fury-shared.module';
import { UpdateMenuReductionComponent } from './step3-reduction/update-menu-reduction/update-menu-reduction.component';
import { UpdateArticleReductionComponent } from './step3-reduction/update-article-reduction/update-article-reduction.component';
import { UpdatePvReductionComponent } from './step3-reduction/update-pv-reduction/update-pv-reduction.component';
import { UpdatePanierReductionComponent } from './step3-reduction/update-panier-reduction/update-panier-reduction.component';
import { UpdateStep2Component } from './step2-menu/update-step2/update-step2.component';
import { UpdateCorpComponent } from './step1-corp/update-corp/update-corp.component';

@NgModule({
  declarations: [SimplificationcorporateComponent, Step1CorpComponent, AddCorpComponent, Step2FormabnComponent, Step2MenuComponent, AddMenuComponent, Step3ReductionComponent, MenuReductionComponent, ArticleReductionComponent, PanierReductionComponent, PvReductionComponent, Step5FormuleComponent, AddStepFormuleComponent, Step6ConditionComponent, AddConditionComponent, UpdateConditionComponent, UpdateStepFormuleComponent, UpdateMenuReductionComponent, UpdateArticleReductionComponent, UpdatePvReductionComponent, UpdatePanierReductionComponent, UpdateStep2Component, UpdateCorpComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    BreadcrumbsModule,
    MatInputModule,
    NgxDatatableModule,
    FuryCardModule,
    ListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSelectModule,
    MatChipsModule,
    FuryCardModule,
    PerfectScrollbarModule,
    QuickInfoWidgetModule,
    MatDatepickerModule,
    MatFormFieldModule,
    FurySharedModule,
    FormsModule,
    BrowserAnimationsModule
  ],
  entryComponents: [AddCorpComponent, AddMenuComponent, MenuReductionComponent, ArticleReductionComponent, PanierReductionComponent, PvReductionComponent, AddStepFormuleComponent,UpdateCorpComponent,
    UpdateStepFormuleComponent, AddConditionComponent, UpdateConditionComponent, UpdateMenuReductionComponent , UpdateArticleReductionComponent , UpdatePvReductionComponent , UpdatePanierReductionComponent , UpdateStep2Component]

})
export class SimplificationcorporateModule { }
