import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GestionpointlivraisonComponent } from './gestionpointlivraison.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule, MatTableModule, MatPaginatorModule, MatSelectModule, MatListModule, MatDatepickerModule, MatFormFieldModule } from '@angular/material';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FurySharedModule } from 'src/@fury/fury-shared.module';
import { BreadcrumbsModule } from 'src/@fury/shared/breadcrumbs/breadcrumbs.module';
import { FuryCardModule } from 'src/@fury/shared/card/card.module';
import { ListModule } from 'src/@fury/shared/list/list.module';
import { MaterialModule } from 'src/@fury/shared/material-components.module';
import { QuickInfoWidgetModule } from '../dashboard/widgets/quick-info-widget/quick-info-widget.module';
import { AddlivraisonComponent } from './addlivraison/addlivraison.component';
import { UpdatelivraisonComponent } from './updatelivraison/updatelivraison.component';
import { PointlivraisonzoneComponent } from './pointlivraisonzone/pointlivraisonzone.component';
import { ZoneComponent } from './pointlivraisonzone/zone/zone.component';
import { ZoneUpdateComponent } from './pointlivraisonzone/zone-update/zone-update.component';

@NgModule({
  declarations: [GestionpointlivraisonComponent, AddlivraisonComponent, UpdatelivraisonComponent, PointlivraisonzoneComponent, ZoneComponent, ZoneUpdateComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatChipsModule,
    ListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSelectModule,
    MatListModule,
    MatChipsModule,
    MaterialModule,
    BreadcrumbsModule,
    NgxDatatableModule,
    FuryCardModule,
    MatChipsModule,
    QuickInfoWidgetModule,
    MatDatepickerModule,
    MatFormFieldModule,
    
    FurySharedModule,
  ],
  entryComponents: [AddlivraisonComponent, UpdatelivraisonComponent , ZoneComponent , ZoneUpdateComponent]
})
export class GestionpointlivraisonModule { }
