import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'fury-add-corp',
  templateUrl: './add-corp.component.html',
  styleUrls: ['./add-corp.component.scss']
})
export class AddCorpComponent implements OnInit {


  step1Form: FormGroup;
  step2Form: FormGroup;
  step3Form: FormGroup;




  constructor(private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddCorpComponent>,) { }

  ngOnInit() {

    this.step1Form = this.fb.group({
      clienttyperaisonsocial: [null, Validators.required],
      clienttypestatutjuridique: [],
      clienttypeemail: [null, Validators.required],
      clienttypeadressepostale: [],
      clienttypelocalisation: [null, Validators.required],
      clienttypetelephone: [null, Validators.required],
    });

    this.step2Form = this.fb.group({
      clienttypepcnom: [null, Validators.required],
      clienttypepcprenom: [],
      clienttypepcstatutfonction: [],
      clienttypepcmail: [],
      clienttypepccontact1: [],
      clienttypepccontact2: [],
    });


    this.step3Form = this.fb.group({
      clienttypefactnom: [],
      clienttypefactfonction: [],
      clienttypefactmodepaie: [],
      clienttypefactdelaipaie: [],
      clienttypefactbon: [],
      clienttypefactcontact2: [],
    });
  }




  submit() {

   

    let responseFinal : any = {} ;
    let response: any = this.step1Form.value;
    let response1: any = this.step2Form.value;
    let response2: any = this.step3Form.value;
    responseFinal.clienttyperaisonsocial = response.clienttyperaisonsocial ;
    responseFinal.clienttypestatutjuridique = response.clienttypestatutjuridique ;
    responseFinal.clienttypeemail = response.clienttypeemail ;
    responseFinal.clienttypeadressepostale = response.clienttypeadressepostale ;
    responseFinal.clienttypelocalisation = response.clienttypelocalisation ;
    responseFinal.clienttypetelephone = response.clienttypetelephone ;

    responseFinal.clienttypepcnom = response1.clienttypepcnom ;
    responseFinal.clienttypepcprenom = response1.clienttypepcprenom ;
    responseFinal.clienttypepcstatutfonction = response1.clienttypepcstatutfonction ;
    responseFinal.clienttypepcmail = response1.clienttypepcmail ;
    responseFinal.clienttypepccontact1 = response1.clienttypepccontact1 ;
    responseFinal.clienttypepccontact2 = response1.clienttypepccontact2 ;

    responseFinal.clienttypefactnom = response2.clienttypefactnom ;
    responseFinal.clienttypefactfonction = response2.clienttypefactfonction ;
    responseFinal.clienttypefactmodepaie = response2.clienttypefactmodepaie ;
    responseFinal.clienttypefactdelaipaie = response2.clienttypefactdelaipaie ;
    responseFinal.clienttypefactbon = response2.clienttypefactbon ;
    responseFinal.clienttypefactcontact2 = response2.clienttypefactcontact2 ;

    
    console.log(responseFinal);
    this.dialogRef.close(responseFinal);
  }

}
