import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'fury-update-corp',
  templateUrl: './update-corp.component.html',
  styleUrls: ['./update-corp.component.scss']
})
export class UpdateCorpComponent implements OnInit {


  step1Form: FormGroup;
  step2Form: FormGroup;
  step3Form: FormGroup;




  constructor(private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UpdateCorpComponent>,) { }

  ngOnInit() {




    this.step1Form = this.fb.group({
      clienttypeid: [null, Validators.required],
      clienttyperaisonsocial: [null, Validators.required],
      clienttypestatutjuridique: [],
      clienttypeemail: [null, Validators.required],
      clienttypeadressepostale: [],
      clienttypelocalisation: [null, Validators.required],
      clienttypetelephone: [null, Validators.required],
    });

    this.step2Form = this.fb.group({
      clienttypepcnom: [null, Validators.required],
      clienttypepcprenom: [],
      clienttypepcstatutfonction: [],
      clienttypepcmail: [],
      clienttypepccontact1: [],
      clienttypepccontact2: [],
    });


    this.step3Form = this.fb.group({
      clienttypefactnom: [],
      clienttypefactfonction: [],
      clienttypefactmodepaie: [],
      clienttypefactdelaipaie: [],
      clienttypefactbon: [],
      clienttypefactcontact2: [],
    });



    let corporate: any = this.data.payload;
    console.log(corporate);
    if (corporate) {
      this.step1Form = this.fb.group({
        clienttypeid: [corporate.clienttypeid, Validators.required],
        clienttyperaisonsocial: [corporate.clienttyperaisonsocial, Validators.required],
        clienttypestatutjuridique: [corporate.clienttypestatutjuridique],
        clienttypeemail: [corporate.clienttypeemail, Validators.required],
        clienttypeadressepostale: [corporate.clienttypeadressepostale],
        clienttypelocalisation: [corporate.clienttypelocalisation, Validators.required],
        clienttypetelephone: [corporate.clienttypetelephone, Validators.required],
      });

      this.step2Form = this.fb.group({
        clienttypepcnom: [corporate.clienttypepcnom, Validators.required],
        clienttypepcprenom: [corporate.clienttypepcprenom],
        clienttypepcstatutfonction: [corporate.clienttypepcstatutfonction],
        clienttypepcmail: [corporate.clienttypepcmail],
        clienttypepccontact1: [corporate.clienttypepccontact1],
        clienttypepccontact2: [corporate.clienttypepccontact2],
      });


      this.step3Form = this.fb.group({
        clienttypefactnom: [corporate.clienttypefactnom],
        clienttypefactfonction: [corporate.clienttypefactfonction],
        clienttypefactmodepaie: [corporate.clienttypefactmodepaie],
        clienttypefactdelaipaie: [corporate.clienttypefactdelaipaie],
        clienttypefactbon: [corporate.clienttypefactbon],
        clienttypefactcontact2: [corporate.clienttypefactcontact2],
      });
    }


  }




  submit() {



    let responseFinal: any = {};
    let response: any = this.step1Form.value;
    let response1: any = this.step2Form.value;
    let response2: any = this.step3Form.value;

    
    responseFinal.clienttypeid = response.clienttypeid;

    responseFinal.clienttyperaisonsocial = response.clienttyperaisonsocial;
    responseFinal.clienttypestatutjuridique = response.clienttypestatutjuridique;
    responseFinal.clienttypeemail = response.clienttypeemail;
    responseFinal.clienttypeadressepostale = response.clienttypeadressepostale;
    responseFinal.clienttypelocalisation = response.clienttypelocalisation;
    responseFinal.clienttypetelephone = response.clienttypetelephone;

    responseFinal.clienttypepcnom = response1.clienttypepcnom;
    responseFinal.clienttypepcprenom = response1.clienttypepcprenom;
    responseFinal.clienttypepcstatutfonction = response1.clienttypepcstatutfonction;
    responseFinal.clienttypepcmail = response1.clienttypepcmail;
    responseFinal.clienttypepccontact1 = response1.clienttypepccontact1;
    responseFinal.clienttypepccontact2 = response1.clienttypepccontact2;

    responseFinal.clienttypefactnom = response2.clienttypefactnom;
    responseFinal.clienttypefactfonction = response2.clienttypefactfonction;
    responseFinal.clienttypefactmodepaie = response2.clienttypefactmodepaie;
    responseFinal.clienttypefactdelaipaie = response2.clienttypefactdelaipaie;
    responseFinal.clienttypefactbon = response2.clienttypefactbon;
    responseFinal.clienttypefactcontact2 = response2.clienttypefactcontact2;


    console.log(responseFinal);
    this.dialogRef.close(responseFinal);
  }


}
