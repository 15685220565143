import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { ClientCorporate } from 'src/app/models/Clienttype';
import { Abattement } from 'src/app/models/pointvente';
import { CategoriecorpoService } from 'src/app/pages/categoriecorpo/categoriecorpo.service';
import { MenuService } from 'src/app/pages/gestionmenu/menu.service';
import { UpdatemenuComponent } from 'src/app/pages/gestionmenu/updatemenu/updatemenu.component';
import { PointventeService } from 'src/app/pages/gestionpointvente/pointvente.service';
import { HeureService } from 'src/app/pages/heurecmd/heure.service';
import { TvaService } from 'src/app/pages/tva/tva.service';

@Component({
  selector: 'fury-update-step-formule',
  templateUrl: './update-step-formule.component.html',
  styleUrls: ['./update-step-formule.component.scss']
})
export class UpdateStepFormuleComponent implements OnInit {


  lsAbattement: any[] = [];

  client: ClientCorporate[] = [];
  categoriecorp: any[] = [];


  myselectedAbbt: any[] = [];

  form: FormGroup;

  getItemSub: Subscription;
  items: Abattement[];
  scrollBarHorizontal = (window.innerWidth < 1200);
  temp = [];
  rows = [];
  columns = [];

  public abattements: string[] = [];


  public typeformule: any[] = [
    {
      "libele": "MENSUEL",
      "duree": 30
    },
    {
      "libele": "TRIMESTRIEL",
      "duree": 90
    },
    {
      "libele": "ANNUEL",
      "duree": 360
    }
  ];


  constructor(
    private categoriecorposerv: CategoriecorpoService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UpdatemenuComponent>,
    private fb: FormBuilder, private menuservice: MenuService,
    private snackbar: MatSnackBar,
    private tvaservice: TvaService,
    private heureservice: HeureService,
    private pointventeservice: PointventeService,
    private logger: NGXLogger
  ) { }

  ngOnInit() {
    this.getall();

    this.form = this.fb.group({
      // categorie: ['', Validators.compose([Validators.required])],
      // corporate: ['', Validators.compose([Validators.required])],
      categorieid: ['', Validators.compose([Validators.required])],
      corporateid: ['', Validators.compose([Validators.required])],
      formuledescription: ['', Validators.compose([Validators.required])],
      formuleduree: [''],
      formuleisprecompte: ['', Validators.compose([Validators.required])],
      // formulelibelle: ['', Validators.compose([Validators.required, Validators.minLength(3)])],
      formulemontant: ['', Validators.compose([Validators.required])],
      formuletype: ['', Validators.compose([Validators.required])],

      //formulemontantdecouvert: [''],
      formulenom: ['', Validators.compose([Validators.required, Validators.minLength(3)])]
      //userpisteaudit: ['', Validators.compose([Validators.required])],
    });


    let formule = this.data.payload;
    if (formule) {
      this.logger.info(formule);

      this.getAbttmt(formule.formuleid);

      this.getallCorporate(formule.corporateid);

    }


    this.form.get('corporateid').valueChanges.subscribe(data => {
      this.logger.info("CHECK -- INFOS ");
      let corporateid: any = data;
      this.getallCorporate(corporateid);
    });


    this.getAllCorporate();



  }


  submit() {


    let request: any = this.form.value;
    request.formulelibelle = request.formulenom;
    request.lsAbattement = [];


    let finalAbtmntArray: any[] = [];
    let finalAbtmnt: any[] = this.abattements;

    finalAbtmnt.forEach(element => {
      console.log(element);
      if (element && element != undefined) {
        finalAbtmntArray.push(element);
      }
    });

    finalAbtmntArray = this.uniqByMap(finalAbtmntArray);
    console.log("AFTER ---->" + finalAbtmntArray.length);
    console.log(finalAbtmntArray);
    request.lsAbattement = finalAbtmntArray;
    request.formuleduree   =request.formuletype;


    /*
    request.formulelibelle = request.formulenom;
    request.lsAbattement = this.abattements;
    let categorie: any = request.categorie;
    let corporate: any = request.corporate;

  //  request.categorie = categorie.categoriecorpolibelle;
    request.categorieid = categorie.categorieid;
    request.corporateid = corporate.corporateid;
  //  request.corporate = corporate.clienttyperaisonsocial;

    let formuletype: any = request.formuletype;
    request.formuleduree = formuletype.duree;
    */

    this.logger.trace(request);
    this.dialogRef.close(request);
  }




  onChangeAbtm(event) {
    let result: any = event;
    if (result.source.selected) {
      this.abattements.push(event.source.value);
    } else {
      this.abattements = this.abattements.filter(item => item != event.source.value);
    }
  }



  getAllCorporate() {
    this.getItemSub = this.categoriecorposerv.getAll('parameter/clienttype')
      .subscribe(data => {
        const statuscode = data.status;
        this.logger.trace('DATA', data);
        switch (statuscode) {
          case 200: {
            this.client = data.body;

            // this.temp = data.body;
            break;
          }

          case 204: {
            // this.snack.open('Aucune TVA disponible', 'OK', { duration: 4000 })
            break;
          }

          default: {
            // this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

            break;
          }

        }
      },
        error => {
          this.logger.error('error');
          this.logger.error(error);

          switch (error.status) {

            default: {

              //    this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

              break;
            }
          }
        });
  }



  getall() {
    this.getItemSub = this.categoriecorposerv.getAll('parameter/abtmnt/getall')
      .subscribe(data => {

        let statuscode = data.status;
        this.logger.trace('DATA', data);

        switch (statuscode) {

          case 200: {
            this.items = data.body;
            console.log("JE VERIFIE MES ABBATEMENTS ");
            console.log(this.items);
            break;
          }

          case 204: {
            break;
          }

          default: {

            break;
          }

        }
      },
        error => {
          this.logger.error("error");
          this.logger.error(error);

          switch (error.status) {

            default: {
              //     this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })

              break;
            }
          }
        });
  }


  getallCorporate(id) {
    this.getItemSub = this.categoriecorposerv.getAll("categoriecorporate/findcategoriecorpo/" + id)
      .subscribe(data => {

        let responseany: any = data;

        this.logger.trace(responseany);

        switch (data.status) {

          case 200: {
            this.categoriecorp = data.body;
            break;
          }

        }
      },
        error => {
          console.log("error");
          console.log(error);

          switch (error.status) {

            default: {

              break;
            }
          }
        });
  }




  getAbttmt(key) {
    this.categoriecorposerv.getAll('formule/checkone/' + key)
      .subscribe(data => {

        let statuscode = data.status;
        this.logger.trace('DATA', data);

        switch (statuscode) {

          case 200: {
            let formule = data.body;

            let isprecpte: any = 'false';

            if (formule.formuleisprecompte) {
              isprecpte = 'true';
            }

            this.form = this.fb.group({
              formuleid: [formule.formuleid || '', Validators.compose([Validators.required])],
              //  categorie: [formule.categorie || '', Validators.compose([Validators.required])],
              // corporate: [formule.corporate || '', Validators.compose([Validators.required])],

              categorieid: [formule.categorieid || '', Validators.compose([Validators.required])],
              corporateid: [formule.corporateid || '', Validators.compose([Validators.required])],

              formuledescription: [formule.formuledescription || '', Validators.compose([Validators.required])],
              formuleduree: formule.formuleduree || [''],
              formuleisprecompte: [isprecpte || '', Validators.compose([Validators.required])],
              // formulelibelle: ['', Validators.compose([Validators.required, Validators.minLength(3)])],
              formulemontant: [formule.formulemontant || '', Validators.compose([Validators.required])],
              formuletype: [formule.formuleduree || '', Validators.compose([Validators.required])],

              //formulemontantdecouvert: [''],
              formulenom: [formule.formulenom || '', Validators.compose([Validators.required, Validators.minLength(3)])]
              //userpisteaudit: ['', Validators.compose([Validators.required])],
            });


            let finalAbtmnt: any[] = formule.lsAbattement;
            let finalAbtmntArray: any[] = [];
            console.log("BEFORE ---->");
            console.log(finalAbtmnt);
            formule.lsAbattement = finalAbtmnt;


            this.abattements = formule.lsAbattement;
            this.myselectedAbbt = this.abattements;


            break;
          }

        }
      },
        error => {
          this.logger.error("error");
          this.logger.error(error);

          switch (error.status) {

            default: {
              this.snackbar.open('Une erreur est survenue!', 'OK', { duration: 4000 })

              break;
            }
          }
        });
  }


  uniqByMap<T>(array: T[]): T[] {
    const map = new Map();
    for (const item of array) {
      map.set(item, item);
    }
    return Array.from(map.values());
  }

}
