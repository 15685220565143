import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'fury-showdelivery',
  templateUrl: './showdelivery.component.html',
  styleUrls: ['./showdelivery.component.scss']
})
export class ShowdeliveryComponent implements OnInit {

  demande : any ;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ShowdeliveryComponent>
    ,private logger: NGXLogger
  ) { }

  ngOnInit() {
    this.buildItem(this.data.payload);
  }

  buildItem(value : any){
    this.demande=value ;
  }

}
