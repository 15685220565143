import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatTableDataSource, MatDialog, MatSnackBar } from '@angular/material';
import { ConfigService } from 'ngx-envconfig';
import { NGXLogger } from 'ngx-logger';
import { NgxPermissionsService } from 'ngx-permissions';
import { Subscription } from 'rxjs';
import { ConfirmationService } from 'src/app/confirm/confirmation.service';
import { ApploaderService } from 'src/app/services/apploader/apploader.service';
import { DeliveryService } from 'src/app/services/delivery.service';
import { ShowficheComponent } from '../fileattente/showfiche/showfiche.component';



interface jsPDFWithPlugin extends jsPDF {
  autoTable: (options: UserOptions) => jsPDF;
}

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
import * as jsPDF from 'jspdf';
import 'jspdf-autotable';
import { UserOptions } from 'jspdf-autotable';




@Component({
  selector: 'fury-gestionincident',
  templateUrl: './gestionincident.component.html',
  styleUrls: ['./gestionincident.component.scss']
})
export class GestionincidentComponent implements OnInit {

  public items: any[];
  public itemsFinal: any[];
  temp = [];
  rows = [];
  columns = [];


  public itemForm: FormGroup;

  public commands: Array<any> = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  public getItemSub: Subscription;

  check: any[] = [
    {
      "libele": "TOUTES LES DEMANDE",
      "values": 2
    },
    {
      "libele": "EN ATTENTE",
      "values": 0
    },
    {
      "libele": "VALIDE",
      "values": 1
    },
    {
      "libele": "INVALIDE",
      "values": -1
    }

  ]


  constructor(private dialog: MatDialog, public formBuilder: FormBuilder,
    private snackbar: MatSnackBar, private commandeserv: DeliveryService,
    private datePipe: DatePipe,
    private confirmService: ConfirmationService, private loader: ApploaderService,
    private permissionsService: NgxPermissionsService, private logger: NGXLogger,
    private configService: ConfigService, private fb: FormBuilder) { }


  ngOnInit() {

  


    let dateNow: any = new Date();
    dateNow = this.datePipe.transform(dateNow, "yyyy-MM-dd");

    this.itemForm = this.fb.group({
      debut: [dateNow + 'T00:01:01'],
      fin: [dateNow + 'T23:59:59'],
      type: [2]
    });


    this.getallIncident();
    /*
    this.refreshDatatable();
    this.search();
    */
  }

  refreshDatatable() {


    let dateNow: any = new Date();
    dateNow = this.datePipe.transform(dateNow, "yyyy-MM-dd");

    this.itemForm = this.fb.group({
      debut: [dateNow + 'T00:01:01'],
      fin: [dateNow + 'T23:59:59'],
      type: [2]
    });

    /*
    this.itemForm.get('type').valueChanges.subscribe(data => {
      console.log(data);
      if (data === 'all') {
        this.itemForm.patchValue({
          type: 'all',
          categorie: '',

        });
      }
      if (data !== 'all') {
        this.itemForm.patchValue({
          type: 'status',
          categorie: data,
        });
      }
    });
    */

  }



  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    var columns = Object.keys(this.temp[0]);
    // Removes last "$$index" from "column"
    columns.splice(columns.length - 1);

    // console.log(columns);
    if (!columns.length)
      return;

    const rows = this.temp.filter(function (d) {
      for (let i = 0; i <= columns.length; i++) {
        let column = columns[i];
        // console.log(d[column]);
        if (d[column] && d[column].toString().toLowerCase().indexOf(val) > -1) {
          return true;
        }
      }
    });

    this.items = rows;
  }






  searchByDate() {



    let date = new Date(this.itemForm.get("debut").value);
    let date1 = new Date(this.itemForm.get("fin").value);

    let request: any = this.itemForm.value;

    let from;
    let to;

    if ((date.toString() != 'Invalid Date')) {
      from = this.itemForm.get("debut").value;
    }

    if (date1.toString() != null) {
      to = this.itemForm.get("fin").value;
    }

    let newDate = new Date(from);
    let newDate1 = new Date(to);

    if ((newDate.toDateString() !== "Invalid Date") && (newDate1.toDateString() !== "Invalid Date")) {
      if (newDate1 >= newDate) {
        request.debut = this.datePipe.transform(newDate, "yyyy-MM-dd HH:mm:ss");
        request.fin = this.datePipe.transform(newDate1, "yyyy-MM-dd HH:mm:ss");
      } else {
        this.snackbar.open('Format de date invalide ', 'OK', { duration: 4000 });
        return;
      }
    } else {
      this.snackbar.open('Format de date invalide ', 'OK', { duration: 4000 });
      return;
    }



    this.commandeserv.post(request, "incident/all/bydate").subscribe(data => {

      this.logger.trace(data);

      switch (data.status) {
        case 200: {

          
          let response: any[] = data.body;
          this.logger.trace('response', response);

          let resp1: any[] = [];
          if (this.itemForm.get('type').value === 2) {
            resp1 = response;
          }

          if (this.itemForm.get('type').value === -1) {

            if (response != null && response.length > 0) {
              for (const i of response) {
                if (i.statusode === -1) {
                  resp1.push(i);
                }
              }
            }

          }

          if (this.itemForm.get('type').value === 1) {
            if (response != null && response.length > 0) {
              for (const i of response) {
                if (i.statusode === 1) {
                  resp1.push(i);
                }
              }
            }
          }

          if (this.itemForm.get('type').value === 0) {
            if (response != null && response.length > 0) {
              for (const i of response) {
                if (i.statusode === 0) {
                  resp1.push(i);
                }
              }
            }
          }


          this.items = this.temp = resp1;
          // this.dataSource = new MatTableDataSource(this.items);


          break;
        }

      }
    },
      error => {
        this.logger.trace("erreur retour");
        // this.loading.dismiss('messagesService.loadMessagesOverview');
        switch (error.status) {



          default: {

            // this.FormReg(" erreur de connexion !!! Veuillez ressayer");
            this.logger.error("Check API", error);

            this.snackbar.open('Echec', 'OK', {
              duration: 10000
            });
            break;
          }
        }
      }
    );

  }



  getallIncident() {
    this.getItemSub = this.commandeserv.get("incident/all")
      .subscribe(data => {

        switch (data.status) {

          default: {

            this.logger.trace('data', data.body);
            let response: any[] = data.body;
            this.logger.trace('response', response);

            let resp1: any[] = [];
            if (this.itemForm.get('type').value === 2) {
              resp1 = response;
            }

            if (this.itemForm.get('type').value === -1) {

              if (response != null && response.length > 0) {
                for (const i of response) {
                  if (i.statusode === -1) {
                    resp1.push(i);
                  }
                }
              }

            }

            if (this.itemForm.get('type').value === 1) {
              if (response != null && response.length > 0) {
                for (const i of response) {
                  if (i.statusode === 1) {
                    resp1.push(i);
                  }
                }
              }
            }

            if (this.itemForm.get('type').value === 0) {
              if (response != null && response.length > 0) {
                for (const i of response) {
                  if (i.statusode === 0) {
                    resp1.push(i);
                  }
                }
              }
            }


            this.items = this.temp = resp1;
            // this.dataSource = new MatTableDataSource(this.items);


            break;
          }


        }
      },
        error => {
          console.log('error');
          console.log(error);

          switch (error.status) {

            default: {

              break;
            }
          }
        });
  }


  checkIncident(row: any, etat: any) {
    let request: any = {};
    let message: string = "";
    if (etat === 1) {
      message = "Vouliez-vous valider cette demande ?"
    } else {
      message = "Vouliez-vous invalider cette demande ?"
    }
    this.confirmService.confirm({ message: message })
      .subscribe(res => {

        console.log(res);
        if (!res) {
          return;
        }

        request.incidentorderid = row.incidentorderid;
        request.statusode = etat;

        this.commandeserv.post(request, "incident/status/update").subscribe(data => {
          this.logger.trace("***************************");

          this.logger.trace(data);

          this.logger.trace("***************************");

          switch (data.status) {
            case 200: {
              this.getallIncident();
              this.snackbar.open('Succes', 'OK', {
                duration: 10000
              });
              break;
            }


          }
        },
          error => {
            this.logger.trace("erreur retour");
            // this.loading.dismiss('messagesService.loadMessagesOverview');
            switch (error.status) {



              default: {

                // this.FormReg(" erreur de connexion !!! Veuillez ressayer");
                this.logger.error("Check API", error);

                this.snackbar.open('Echec', 'OK', {
                  duration: 10000
                });
                break;
              }
            }
          }
        );
      });
  }

  checkIncidentX(row: any, etat: any) {

    let request: any = {};

    let message: string = "";


    if (etat === 1) {
      message = "Vouliez-vous valider cette demande ?"
    } else {
      message = "Vouliez-vous invalider cette demande ?"
    }



    request.incidentorderid = row.incidentorderid;
    request.statusode = etat;


    this.commandeserv.post(request, "incident/listpertes").subscribe(data => {

      this.logger.trace(data);


      switch (data.status) {
        case 200: {

          let response: any = data.body;

          let resp1: any[] = response.listFileAttente;
          console.log(resp1);

          this.items = this.temp = resp1;

          this.snackbar.open('Succes', 'OK', {
            duration: 10000
          });
          break;
        }

      }
    },
      error => {
        this.logger.trace("erreur retour");
        // this.loading.dismiss('messagesService.loadMessagesOverview');
        switch (error.status) {



          default: {

            // this.FormReg(" erreur de connexion !!! Veuillez ressayer");
            this.logger.error("Check API", error);

            this.snackbar.open('Echec', 'OK', {
              duration: 10000
            });
            break;
          }
        }
      }
    );
  }


  search() {
    let date = new Date(this.itemForm.get("debut").value);
    let date1 = new Date(this.itemForm.get("fin").value);

    let request: any = this.itemForm.value;

    let from;
    let to;

    if ((date.toString() != 'Invalid Date')) {
      from = this.itemForm.get("debut").value;
    }

    if (date1.toString() != null) {
      to = this.itemForm.get("fin").value;
    }

    let newDate = new Date(from);
    let newDate1 = new Date(to);

    if ((newDate.toDateString() !== "Invalid Date") && (newDate1.toDateString() !== "Invalid Date")) {
      if (newDate1 >= newDate) {
        request.debut = this.datePipe.transform(newDate, "yyyy-MM-dd HH:mm:ss");
        request.fin = this.datePipe.transform(newDate1, "yyyy-MM-dd HH:mm:ss");
      } else {
        this.snackbar.open('Format de date invalide ', 'OK', { duration: 4000 });
        return;
      }
    } else {
      this.snackbar.open('Format de date invalide ', 'OK', { duration: 4000 });
      return;
    }

    if (request.type === "all") {
      request.categorie = "";
    }

    if (request.type !== "all") {
      request.categorie = request.type;
      request.type = "status";
    }

    console.log(request);

    this.commandeserv.post(request, "incident/listpertes").subscribe(data => {

      this.logger.trace(data);


      switch (data.status) {
        case 200: {

          let response: any[] = data.body;
          this.logger.trace('response', response);

          let resp1: any[] = [];
          if (this.itemForm.get('type').value === 2) {
            resp1 = response;
          }

          if (this.itemForm.get('type').value === -1) {

            if (response != null && response.length > 0) {
              for (const i of response) {
                if (i.statusode === -1) {
                  resp1.push(i);
                }
              }
            }

          }

          if (this.itemForm.get('type').value === 1) {
            if (response != null && response.length > 0) {
              for (const i of response) {
                if (i.statusode === 1) {
                  resp1.push(i);
                }
              }
            }
          }

          if (this.itemForm.get('type').value === 0) {
            if (response != null && response.length > 0) {
              for (const i of response) {
                if (i.statusode === 0) {
                  resp1.push(i);
                }
              }
            }
          }


          this.items = this.temp = resp1;
          // this.dataSource = new MatTableDataSource(this.items);


          break;
        }

      }
    },
      error => {
        this.logger.trace("erreur retour");
        // this.loading.dismiss('messagesService.loadMessagesOverview');
        switch (error.status) {



          default: {

            // this.FormReg(" erreur de connexion !!! Veuillez ressayer");
            this.logger.error("Check API", error);

            this.snackbar.open('Echec', 'OK', {
              duration: 10000
            });
            break;
          }
        }
      }
    );

  }



  
  exportPdf() {
    let itemscsv: any[] = [];
    let itemslocal: any[] = this.items;
    if (itemslocal.length > 0) {
      itemslocal.forEach(function (element) {

        if (element.date.length > 17) {
          element.date = element.date.substr(0, 16);
        }


      

        var my_json = {
          "Date": element.date,
          "Reference": element.codecommande,
          "Motif": element.motif,
          "Total A payer": element.total,
          "Type": element.type,
          "Status": element.status
        };

        itemscsv.push(my_json);
      });
    }

    console.log(itemscsv);
    var columns = [
      { title: "Date", dataKey: "Date" },
      { title: "Reference", dataKey: "Reference" },
      { title: "Motif", dataKey: "Motif" },
      { title: "Total A payer", dataKey: "Total A payer" },
      { title: "Type", dataKey: "Type" },
      { title: "Status", dataKey: "Status" }
    ];
    var rows = itemscsv;

    const doc = new jsPDF('portrait', 'px', 'a4') as jsPDFWithPlugin;
    doc.autoTable({
      body: rows,
      columns: columns,
      didParseCell: function (data) {
        if (data.column.raw.displayProperty) {
          var prop = data.column.raw.displayProperty;
          var text = data.cell.raw[prop];
          if (text && text.length > 0) {
            data.cell.text = text;
          }
        }
      }
    });
    doc.save('incident.pdf');
  }

  exportXls() {
    let itemscsv: any[] = [];
    let itemslocal: any[] = this.items;
    if (itemslocal.length > 0) {
      itemslocal.forEach(function (element) {
        console.log(element);

        if (element.date.length > 17) {
          element.date = element.date.substr(0, 16);
        }


      

        var my_json = {
          "Date": element.date,
          "Reference": element.codecommande,
          "Motif": element.motif,
          "Total A payer": element.total,
          "Type": element.type,
          "Status": element.status
        };

        itemscsv.push(my_json);
      });
      console.log(itemscsv);

      this.exportAsExcelFile(itemscsv, 'incident');
    }
  }




  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }



  getCommande(ref) {
    this.getItemSub = this.commandeserv.get("fileattente/findbyid/" + ref)
      .subscribe(data => {
        switch (data.status) {
          default: {
            this.logger.trace('data', data.body);
            let response: any = data.body;


            this.dialog.open(ShowficheComponent,
              {
                width: '720px',
                data: { title: '', payload: response }
              }
            ).afterClosed().subscribe((res) => {
              console.log(res);
              if (!res) {
                return;
              }
            });
            break;
          }
        }
      },
        error => {
          console.log('error');
          console.log(error);
          switch (error.status) {
            default: {
              break;
            }
          }
        });
  }

  verifyFalse(value: any) {
    if (value === true) {
      return 'oui';
    } else if (value === false) {
      return 'non';
    }
  }

  verifyStatus(value: any) {
    if (value === "delivery.level1") {
      return 'EN ATTENTE PRISE EN COMPTE CUISINE';
    } else if (value === "delivery.level2") {
      return 'EN ATTENTE FIN PREPARATION CUISINE';
    } else if (value === "delivery.level3") {
      return 'EN ATTENTE PRISE EN COMPTE EXPEDITEUR';
    } else if (value === "delivery.level4") {
      return 'EN ATTENTE FIN PACKAGING';
    } else if (value === "delivery.level5") {
      return 'EN ATTENTE ATTRIBUTION LIVREUR';
    } else if (value === "delivery.level6") {
      return 'EN COURS DE LIVRAISON';
    }
  }
}



